











































import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DownloadModule } from '@/store/vuex/fiscal/DownloadStore'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DialogoDeIdentificadorDownload extends Vue {
	mostra = false
	identificador = ''

	async mostrar() {
		this.mostra = true
	}

	async confirmar() {
		if (DownloadModule.downloadsNF.some(d => d.identificador === this.identificador)) {
			AlertModule.setError('Já existe um download com esse identificador em andamento')
			return
		}

		this.$emit('confirma', this.identificador)
		this.identificador = ''
		this.esconder()
	}

	async cancelar() {
		this.identificador = ''
		this.esconder()
	}

	async esconder() {
		this.mostra = false
	}
}
