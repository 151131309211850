
































































































































































































































import { CapaDaNota, FiltroDeCapaDaNota, TipoDaNota } from '@/models'
import { DataOptions } from 'vuetify'
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import DialogoDeIdentificadorDownload from '@/components/ui/DialogoDeIdentificadorDownload.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import { backendDomain, nextTick } from '@/shareds/utils'
import { DownloadNotasUseCase, FindCapaDaNotaUseCase, EmailUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { sortConverter } from '@/shareds/paginacao'
import moment from 'moment'
import { formatarCnpjOuCpf } from '@/shareds/formatadores'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { AmbienteFiscal } from '@/models/SerieFiscal'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { serializarQueryString } from '@/common/request'
import { obrigatorio, emailInvalido } from '@/shareds/regras-de-form'

const FILTRO_DE_NOTAS = 'FILTRO_DE_NOTAS'

@Component({
	components:{
		SeletorDeLojasDoUsuario,
		DatePicker,
		DialogoDeIdentificadorDownload,
	},
})
export default class TelaDeDocumentosFiscais extends Vue {

	@Ref()
	dialogoDeIdentificadorDownload!: DialogoDeIdentificadorDownload
	@Ref() form!: HTMLFormElement
	@Ref() formEmail!: HTMLFormElement

	headers = [
		{ text: 'Data de Emissão', value: 'displayDataEntradaEmissao', sortable: false  },
		{ text: 'Emitente', value: 'xNomeEmit', sortable: false },
		{ text: 'Destinatário', value: 'xNomeDest', sortable: false},
		{ text: 'Nf/Série', value: 'displayNfSerie', sortable: false },
		{ text: 'Status', value: 'displayStatusDaNota', sortable: false },
		{ text: 'E/S', value: 'displayEntradaOuSaida', sortable: false },
		{ text: 'R$ Valor', value: 'displayValor', sortable: false},
	]

	buscando = false
	capaDaNota: CapaDaNota[] = []
	dialogoEnvioEmailAberto = false
	emailDestino = ''
	enviandoXmls = false

	findUseCase = new FindCapaDaNotaUseCase()
	// gerarDownloadUseCase = new CreateDownloadUseCase()
	downloadNotasUseCase = new DownloadNotasUseCase()
	emailUseCase = new EmailUseCase()
	
	baixandoXmls = false

	obrigatorio = obrigatorio

	emailInvalido = emailInvalido	

	filtro: FiltroDaNota = localStorage[FILTRO_DE_NOTAS]
		? JSON.parse(localStorage[FILTRO_DE_NOTAS])
		: {
			busca:'',
			lojaId: null,
			dataInicial: null,
			dataFinal: null,
			serieFiscal: null,
			ambientes: ["Produção"],
			notaInicial: null,
			notaFinal: null,
			tpnf: null,
			exibirNotasValidas: false,
			somenteCompra:false,
		}
	totalRegistros = -1

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	aplicarFiltros() {
		this.paginacao = {
			...this.paginacao,
			page: 1,
		}
	}

	normalizarFiltros(filtro: FiltroDaNota): FiltroDeCapaDaNota {
		const dataInicial = filtro.dataInicial
			? this.gerarDataHora(filtro.dataInicial, '00:00:00')
			: undefined
		const dataFinal = filtro.dataFinal
			? this.gerarDataHora(filtro.dataFinal, '23:59:59')
			: undefined
		const sorted = sortConverter(this.paginacao)
		return {
			dataInicial,
			dataFinal,
			lojaId: this.filtro.lojaId || undefined,
			serie: this.filtro.serieFiscal || undefined,
			ambientes: this.filtro.ambientes || undefined,
			notaInicial: this.filtro.notaInicial || undefined,
			notaFinal: this.filtro.notaFinal || undefined,
			tpnf: this.filtro.tpnf || undefined,
			exibirSomenteNotasValidas: this.filtro.exibirSomenteNotasValidas || undefined,
			somenteCompras: this.filtro.somenteCompras || undefined,
			page: this.paginacao.page -1,
			size: this.paginacao.itemsPerPage,
			sort: !sorted.length ? ['dhEmi,desc'] : sorted,
		}
	}

	async buscar() {
		if (!this.form.validate()) return
		if (this.buscando) return
		await nextTick()
		try {
			this.buscando = true
			const page = await this.findUseCase.find(this.normalizarFiltros(this.filtro))
			this.capaDaNota = page.content
			this.totalRegistros = page.totalElements

		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	async baixarXmls() {
		if (this.baixandoXmls) return
		await nextTick()
		try {
			this.baixandoXmls = true
			await this.findUseCase.downloadXml(this.normalizarFiltros(this.filtro))
			AlertModule.setSuccess({
				text: 'Exportação iniciada, avisaremos quando estiver sido concluída',
				timeout: -1,
			})
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.baixandoXmls = false
		}
	}

	async criarDownloadXmls() {
		await this.dialogoDeIdentificadorDownload.mostrar()
	}

	limparFiltros() {
		this.filtro = {
			busca:'',
			lojaId: null,
			dataInicial: null,
			dataFinal: null,
			serieFiscal: '',
			ambientes: ["Produção"],
			notaInicial: '',
			notaFinal: '',
			tpnf: null,
			exibirSomenteNotasValidas: false,
			somenteCompras:false,
		}
		this.capaDaNota = []
		this.form.resetValidation();
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	get itens() {
		return this.capaDaNota.map(capaDaNota => ({
			...capaDaNota,
			displayNfSerie: this.displayNfSerie(capaDaNota),
			displayCnpjEmitente: this.displayCnpj(capaDaNota.cnpjEmit),
			displayCnpjDestinatario: this.displayCnpj(capaDaNota.cnpjDest),
			displayStatusDaNota: this.displayStatusDaNota(capaDaNota),
			displayDataEntradaEmissao: this.displayDataEntradaEmissao(capaDaNota),
			displayEntradaOuSaida: this.displayEntradaOuSaida(capaDaNota),
			displayValor: capaDaNota ? this.displayValor(capaDaNota) : '0,00' ,
		}))
	}

	displayStatusDaNota(capaDaNota: CapaDaNota) {
		const nota = capaDaNota
		return nota && mapErrosSefaz[nota.codigoDeStatus]
			? nota.codigoDeStatus + ' - ' + mapErrosSefaz[nota.codigoDeStatus]
			: nota?.codigoDeStatus || ''
	}

	displayNfSerie (capaDaNota: CapaDaNota) {
		const nota = capaDaNota
		return nota
			? nota.serie
			: ''
	}

	displayCnpj(cnpj: string) {
		if(!cnpj) return ''
		return formatarCnpjOuCpf(cnpj)
	}

	displayDataEntradaEmissao(capaDaNota: CapaDaNota) {
		const dataHoraEntradaEmissao = capaDaNota.dhEmi
		return dateTimeToPtBrFormat(dataHoraEntradaEmissao)
	}

	displayEntradaOuSaida(capaDaNota: CapaDaNota) {
		return capaDaNota.tpNF == 0 ?  'Entrada' : 'Saída'
	}

	displayValor(capaDaNota: CapaDaNota) {
		return capaDaNota.vnf.replace('.',',')
	}

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		const stringified = JSON.stringify(newFiltro);
		localStorage.setItem(FILTRO_DE_NOTAS, stringified)
	}

	set atualizarFiltroTpnf(value: string) {
		if(this.filtro.somenteCompras){
			this.filtro.tpnf = '0'
		}
		if (value === 'Entrada') {
			this.filtro.tpnf = '0'
		} else if(value === 'Saída') {
			this.filtro.tpnf = '1'
		} else {
			this.filtro.tpnf = null
		}
	}

	get atualizarFiltroTpnf() {
		if (this.filtro.somenteCompras) {
			this.filtro.tpnf = '0'
		}
		if (this.filtro.tpnf === '0') {
			return 'Entrada'
		} else if(this.filtro.tpnf === '1') {
			return 'Saída'
		} else {
			return 'Entrada e Saída'
		}
	}

	get urlDeRelatoriosDeEntradaDeNotasNfce() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.normalizarFiltros(this.filtro),
		})
		return `${backendDomain()}/notas-fiscais/relacao-de-entradas/RelacaoDeEntradasDeNotasNfe.csv?${queryString}`
	}	

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar	

	abrirDialogoEnvioEmail() {
		if (!this.form.validate()) return				
		return	this.dialogoEnvioEmailAberto = true;		
	}	

	enviarEmail() {		
		if (!this.formEmail.validate()) return		

		this.enviarXmlsPorEmail();

		setTimeout(() => {
			this.fecharDialogoEnvioEmail();
			AlertModule.setSuccess('XMLs enviados com sucesso!');
		}, 4000);  
	}

	fecharDialogoEnvioEmail() {
		this.dialogoEnvioEmailAberto = false		
		this.emailDestino = ''
	}	

	async enviarXmlsPorEmail() {		
		if (this.enviandoXmls) return				

		try {
			this.enviandoXmls = true;	

			await this.emailUseCase.enviarXmlsPorEmail(
				this.normalizarFiltros(this.filtro),
				this.emailDestino,								
			);			
			
		} catch (error) {
			AlertModule.setError('Falha ao enviar XMLs por e-mail.');
		} finally {
			this.enviandoXmls = false;
		}
	}
}

export interface FiltroDaNota {
	busca: string
	lojaId: string | null
	dataInicial:  string | null
	dataFinal:  string | null
	serieFiscal: string
	ambientes: AmbienteFiscal[]
	notaInicial: string | null
	notaFinal: string | null
	tpnf: TipoDaNota | null
	exibirSomenteNotasValidas: boolean
	somenteCompras?: boolean
}
